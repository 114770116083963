#main {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  cursor: pointer;
}

#cheers {
  width: 100px;
  height: 100px;
}

#prompt {
  user-select: none;
  pointer-events: none;
}

#help {
  width: 100%;
}
