.inputContainer {
  display: flex;
  align-items: center;
  border-radius: 0.2rem;
  color: var(--text-color);
  border: var(--border-width) solid var(--border-color);
  background-color: transparent;
  height: var(--field-height);
}

.inputContainer[data-disabled='true'] {
  opacity: 0.5;
  pointer-events: none;
  visibility: hidden;
}

main {
  display: flex;
  padding: 0.5rem 0.8rem;
  width: 100%;
  height: 100%;
  align-items: center;
}

.input {
  border: none;
  color: var(--text-color);
  background-color: transparent;
  font-size: 1rem;
  outline: none;
  width: 100%;
}

.input::placeholder {
  color: var(--border-color);
}

#clearNameInput {
  border: 0;
  padding: 0;
}

#clearNameInput:disabled {
  visibility: hidden;
}

#inputButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 4rem;

  overflow: hidden;
  padding: 0rem 1rem;
  padding: 0;
  margin: 0;
  background-color: var(--accent-color);
  border-radius: 0;
  transition: opacity 0.2s ease-in-out;

  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

#inputButton:disabled {
  opacity: 0.2;
  color: var(--text-color);
  transition: opacity 0.2s ease-in-out;
}

.inputContainer:has(#inputButton) > main {
  border-right: var(--border-width) solid var(--border-color);
}
