.App {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

#content {
  display: flex;
  flex-direction: column;
  width: 35rem;
}

:root {
  --primary-color: #111213;
  --secondary-color: #26292d;
  --accent-color: #70707088;
  --text-color: #b3b3b3;
  --icon-color: var(--text-color);
  --field-height: 3rem;
  color: var(--text-color);

  --border-width: 1px;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100%;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
  cursor: pointer;
  border-radius: 10px;
}
