.viewTitle {
  margin-top: 2rem;
  text-align: start;
}

.view {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  width: 100%;
}

header {
  display: flex;
  justify-content: space-between;
}

#settings {
  margin-top: auto;
}
