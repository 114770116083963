.select {
  position: relative;
  display: flex;
  width: 100%;
}

.selected {
  width: 100%;
  justify-content: start;
}

.options {
  position: absolute;
  left: 0;
  top: 95%;
  /* transform: translateY(40%); */

  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
  overflow: hidden;
  background-color: var(--primary-color);
  padding: 0.5rem;
  gap: 0.5rem;

  width: 100%;
  z-index: 101;
}

.option {
  justify-content: start;
  background-color: red;
}

.option:is(:hover) {
  opacity: 0.8;
}

#arrow {
  margin-left: auto;
}
.flip {
  transform: rotate(180deg);
}
