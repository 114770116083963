#settings {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
  width: 20rem;
  max-width: 100%;
}

#settings > section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.option {
  justify-content: start;
  gap: 1rem;
}
