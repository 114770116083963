#announcement {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;

  width: 100%;
  height: 100%;
}

#message {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem 1rem;
  align-items: baseline;
}

.player {
  font-weight: 700;
  /* color: var(--theme-color); */
}
