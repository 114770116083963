#exit {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  width: 20rem;
  max-width: 100%;
}

.option {
  justify-content: start;
  gap: 1rem;
}

#message {
  text-align: center;
}

#stay {
  background-color: var(--theme-color);
}

#buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
