.playerListItem {
  display: flex;
  gap: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 1rem;
  border: 1px solid var(--border-color);
  border-radius: 0.2rem;
  height: var(--field-height);
  min-height: var(--field-height);
  position: relative;
  overflow: hidden;
}

.playerName {
  padding: 0.3rem;
  background-color: var(--primary-color);
  border-radius: 0.5rem;
  line-height: 100%;
  z-index: 2;
}

.backdrop {
  position: absolute;
  width: 130%;
  height: 150%;
  top: -140%;
  left: -10%;
  rotate: -13deg;
  opacity: 0.1;
  color: var(--text-color);
  font-weight: 700;
  user-select: none;
  pointer-events: none;
  z-index: 1;
}

.remove {
  padding: 0;
}
