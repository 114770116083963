#start {
  width: 25rem;
  min-height: max-content;
  height: max-content;
}

#content {
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
}

#message {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

#bottom {
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

#insufficient {
  padding-top: 0.5rem;
  font-size: small;
  text-align: center;
}

#confirm {
  background-color: var(--theme-color);
}

#accept {
  border: 0;
  background-color: transparent;
}
