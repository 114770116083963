#help {
  width: 23rem;
  display: flex;
  flex-direction: column;
  height: 15rem;
}

#message {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  gap: 0.5rem;
}

#coming {
  height: 100%;
  display: flex;
  align-items: center;
}

#update {
  padding-top: 1rem;
  font-size: small;
}

#proceed {
  margin-top: auto;
  background-color: var(--theme-color);
}
