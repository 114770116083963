#continueModal {
  width: 25rem;
  max-width: 100%;
}

#continue {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: 100%;
}

#donate {
  background-color: var(--theme-color);
}

#donationText {
  text-align: center;
}

#gameFinishedFlag {
  width: 5rem;
  height: 5rem;
  aspect-ratio: 1;
}

#buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
