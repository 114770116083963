#footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  padding-top: 0;
  gap: 0.5rem;
}

#by {
  font-size: 0.9rem;
  /* padding: 0.5rem; */
}

#links {
  display: flex;
  gap: 0.5rem;
}

#github {
  height: 100%;
}
