.switch {
  justify-content: start;
  gap: 0.5rem;
}

.check {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  border-width: 1px;

  transition: background 0.2s ease-in-out;
}

.check[data-checked='true'] {
  background-color: var(--theme-color);

  transition: background 0.2s ease-in-out;
}
