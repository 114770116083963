.button {
  background-color: transparent;
  border: var(--border-width) solid var(--border-color);
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 0.2rem;
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  transition: opacity 0.2s ease-in-out;
}

.button.bold {
  font-weight: bold;
  font-size: 15.6px;
}

.button:not(.noheight) {
  height: var(--field-height);
  min-height: var(--field-height);
}

.button:disabled {
  opacity: 0.4;
  cursor: default;

  transition: opacity 0.2s ease-in-out;
}

.button.filled {
  background-color: var(--secondary-color);
  border: none;
}

.button.filled.primary {
  background-color: var(--primary-color);
}

.button.flat {
  border: none;
  background-color: transparent;
  height: min-content;
}
