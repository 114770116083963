#container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}

#prompt {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#players {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.player {
  font-weight: 700;
  text-decoration: underline;
  /* color: var(--theme-color); */
}
