#welcome {
  width: 23rem;
}

#message {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  gap: 0.5rem;
}

#update {
  padding-top: 1rem;
  font-size: small;
}

#proceed {
  background-color: var(--theme-color);
}
