#lobbyTitle {
  margin-top: 2rem;
  text-align: start;
}

#lobbyMessage {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

#noPlayers {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: var(--text-color);
}

#noPlayers > img {
  color: red;
}

#ghost {
  width: 6rem;
  height: 6rem;
}

#morePlayers {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

#startGame {
  background-color: var(--theme-color);
}
